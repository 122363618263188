import { Model, attr } from 'redux-orm';
import { createAction, createRequestConst } from '../util';
import { addDerived } from './util/fspProcessingFees';

export default class FSPProcessingFees extends Model {
  static get modelName() {
    return 'FSPProcessingFees';
  }

  static reducer({ type, data }, FSPProcessingFees) {
    switch (type) {
      case LOAD.SUCCESS:
        FSPProcessingFees.delete();
        data?.response?.forEach((item) =>
          FSPProcessingFees.upsert(addDerived(item))
        );
        break;
      case LOAD.FAILURE:
        FSPProcessingFees.delete();
        break;
      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      offset: attr(),
      id: attr(),
      href: attr(),
      feesAmount: attr(),
      feesAmountDerived: attr(),
      description: attr(),
      transactionTypeIdentifier: attr(),
      fundingTypeId: attr(),
      fundingType: attr(),
      insertedAt: attr(),
      insertedUser: attr(),
      insertedAdminUserId: attr(),
      updatedAt: attr(),
      updatedUser: attr(),
      updatedAdminUserId: attr(),
    };
  }

  /**
   * Use fspId as key
   * */
  static options = {
    idAttribute: 'fundingTypeId',
  };
}

const name = FSPProcessingFees.modelName;

export const LOAD = createRequestConst(name);

export const load = createAction(LOAD);
