import { useFSPId } from 'hooks';
import { useSelector } from 'react-redux';
import { isOn } from '../util';
import { selector } from '../selectors/fspConfig';
import useDispatchLoadEffect from './useFSPConfig/useDispatchLoadEffect';
import { selectedFspIdSelector } from '../selectors/fsp';

const usePayALoanOffOn = (fspId) => {
  let fspIdDerived = 0;
  if (fspId) {
    fspIdDerived = fspId;
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    fspIdDerived = useFSPId();
  }

  const fspIdSelected = useSelector(selectedFspIdSelector);
  const fspIdValue = fspIdDerived ?? fspIdSelected;
  const fspConfig = useSelector((state) => selector(state, fspIdValue));

  useDispatchLoadEffect(fspIdValue, fspConfig);

  return isOn(fspConfig?.palOffOn);
};

export default usePayALoanOffOn;
