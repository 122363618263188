import { LOAD } from 'model/fspConfigPayALoan';
import { load } from 'sdk/fspConfigPayALoan';
import { takeLatest } from 'redux-saga/effects';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeLatest(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );
}
