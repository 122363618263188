import { client } from 'util/sdk';
import { GET, POST, PUT } from 'util/api';

const returnModel = { name: 'fspDenyListItems' };
const queryParams = ({ id }) => ({ adminUserId: id });
const queryParamsForUpdate = ({ id }, lastUpdatedAt) => ({
  adminUserId: id,
  lastUpdatedAt,
});
const denyListConfigUri = (fspId) => `v2/fsps/${fspId}/deny-list-offerings`;

export const loadDenyListConfig = (fspId, auth) =>
  client.executeRequest(GET, denyListConfigUri(fspId), {
    queryParams: queryParams(auth),
    returnModel,
  });

export const createDenyListConfig = ({ fspId, ...data }, auth) =>
  client.executeRequest(POST, denyListConfigUri(fspId), {
    data,
    queryParams: queryParams(auth),
    returnModel,
  });

export const updateDenyListConfig = ({ fspId, lastUpdatedAt, ...data }, auth) =>
  client.executeRequest(PUT, denyListConfigUri(fspId), {
    data,
    queryParams: queryParamsForUpdate(auth, lastUpdatedAt),
    returnModel,
  });
