import { client } from 'util/sdk';
import { GET } from 'util/api';

const returnModel = { name: 'FspProcessingFees' };
// eslint-disable-next-line import/prefer-default-export
export const load = ({ fspId }, auth) =>
  client.executeRequest(GET, `/v2/fsps/${fspId}/processingfees`, {
    queryParams: { adminUserId: auth.id },
    returnModel,
  });
