import { ORM } from 'redux-orm';
import ActionableAlert from './actionableAlert';
import ActionInsight from './actionInsight';
import ActionInsightEvent from './actionInsightEvent';
import ActionInsightReduceMyBill from './actionInsightReduceMyBill';
import ApiKey from './apiKey';
import AutomatchJob from './automatchJob';
import AutomatchScope from './automatchScope';
import AutomatchTemplate from './automatchTemplate';
import Autopay from './autopay';
import AutopayAudit from './autopayAudit';
import AutopayScheduled from './autopayScheduled';
import Biller from './biller';
import BillerAddress from './billerAddress';
import BillerAlias from './billerAlias';
import BillerContact from './billerContact';
import BillerLogo from './billerLogo';
import BillerLogoChooser from './billerLogoChooser';
import BillerMask from './billerMask';
import Billernet from './billernet';
import BillerSet from './billerSet';
import BillerSetAlias from './billerSetAlias';
import BillerSetBiller from './billerSetBiller';
import Channel from './channel';
import CoreProcessor from './coreProcessor';
import CorsDomain from './corsDomain';
import DDAMask from './ddaMask';
import DisburseType from './disburseType';
import Distributor from './distributor';
import EBillAccount from './eBillAccount';
import EBillEnrollment from './eBillEnrollment';
import EBillEnrollmentAudit from './eBillEnrollmentAudit';
import EBiller from './eBiller';
import EBillernet from './eBillernet';
import EBillUser from './eBillUser';
import Export from './export';
import ExternalAccount from './externalAccount';
import ExternalAccountAudit from './exterrnalAccountAudit';
import ExternalAccountIAV from './externalAccountIAV';
import FinancialInstitution from './financialInstitution';
import FraudFSPOverride from './fraudFSPOverride';
import FSP from './fsp';
import FspAudit from './fspAudit';
import FSPConfig from './fspConfig';
import FSPCoreConfig from './fspCoreConfig';
import FspConfigPayALoan from './fspConfigPayALoan';
import FSPDatabase from './fspDatabase';
import FSPProcessingFees from './fspProcessingFees';
import FSPFraudConfig from './fspFraudConfig';
import FSPFraudRuleThresholds from './fspFraudRuleThresholds';
import FSPFraudTrans from './fspFraudTrans';
import FSPPayerFraudOverride from './fspPayerFraudOverride';
import FSPPayerType from './fspPayerType';
import FSPPaynet from './fspPaynet';
import FSPSchedTrans from './fspSchedTrans';
import Group from './group';
import InternalAccount from './internalAccount';
import InternalAccountAudit from './interrnalAccountAudit';
import Limit from './limit';
import LimitRule from './limitRule';
import LimitRuleImport from './limitRuleImport';
import Notification from './notification';
import OneTimePasscode from './oneTimePasscode';
import OneTimePasscodeAudit from './oneTimePasscodeAudit';
import OneTimePasscodeEvent from './oneTimePasscodeEvent';
import OneTimePasscodeEventConfig from './oneTimePasscodeEventConfig';
import OneTimePasscodeInfo from './oneTimePasscodeInfo';
import OnHoldRule from './onHoldRule';
import OntracUser from './ontracUser';
import Org from './org';
import PageSetting from './pageSetting';
import PayALoan from './payALoan';
import PayALoanAudit from './payALoanAudit';
import Payee from './payee';
import PayeeAudit from './payeeAudit';
import Payer from './payer';
import PayerAudit from './payerAudit';
import PayerSearch from './payerSearch';
import PayerType from './payerType';
import PayerUser from './payerUser';
import PayerUserAudit from './payerUserAudit';
import PaymentResearch from './paymentResearch';
import PaymentResearchSearch from './paymentResearchSearch';
import Paynet from './paynet';
import Permission from './permission';
import ProcessingWindow from './processingWindow';
import Reason from './reason';
import RecentFSP from './recentFsp';
import RecentPayer from './recentPayer';
import RecentTicket from './recentTicket';
import RecentTicketPaymentOps from './recentTicketPaymentOps';
import Recipient from './recipient';
import RecipientAudit from './recipientAudit';
import RecipientDirect from './recipientDirect';
import Report from './report';
import ReportType from './reportType';
import ResearchNote from './researchNote';
import ResearchReason from './researchReason';
import Role from './role';
import Route from './route';
import Rtn from './rtn';
import RtnInfo from './rtnInfo';
import SchedTranFraud from './schedTranFraud';
import ScheduledTransaction from './scheduledTransaction';
import ScheduledTransactionAudit from './scheduledTransactionAudit';
import ScrollRef from './scrollRef';
import SSOErrorLog from './ssoErrorLog';
import SSOUri from './ssoUri';
import Switch from './switch';
import SwitchAddress from './switchAddress';
import SwitchAlias from './switchAlias';
import SwitchMask from './switchMask';
import SwitchMatch from './switchMatch';
import TransactionHistory from './transactionHistory';
import TransactionHistoryAudit from './transactionHistoryAudit';
import User from './user';
import UserFspConfig from './userFspConfig';
import UserProfile from './userProfile';
import UserRole from './userRole';
import ViewAs from './viewAs';
import FspDenyList from './fspDenyList';
import FspDenyListConfig from './fspDenyListConfig';
import FspDenyListOfferings from './fspDenyListOfferings';
import DeliveryCountByType from './deliveryCountByType';

const name = 'orm';

export const orm = new ORM({ stateSelector: (state) => state?.[name] });

orm.register(
  ActionableAlert,
  ActionInsight,
  ActionInsightEvent,
  ActionInsightReduceMyBill,
  ApiKey,
  AutomatchJob,
  AutomatchScope,
  AutomatchTemplate,
  Autopay,
  AutopayAudit,
  AutopayScheduled,
  Biller,
  BillerAddress,
  BillerAlias,
  BillerContact,
  BillerLogo,
  BillerLogoChooser,
  BillerMask,
  Billernet,
  BillerSet,
  BillerSetAlias,
  BillerSetBiller,
  Channel,
  CoreProcessor,
  CorsDomain,
  DDAMask,
  DeliveryCountByType,
  DisburseType,
  Distributor,
  EBillAccount,
  EBillEnrollment,
  EBillEnrollmentAudit,
  EBiller,
  EBillernet,
  EBillUser,
  Export,
  ExternalAccount,
  ExternalAccountAudit,
  ExternalAccountIAV,
  FinancialInstitution,
  FraudFSPOverride,
  FSP,
  FspAudit,
  FSPConfig,
  FspConfigPayALoan,
  FSPCoreConfig,
  FSPDatabase,
  FspDenyList,
  FspDenyListConfig,
  FspDenyListOfferings,
  FSPProcessingFees,
  FSPFraudConfig,
  FSPFraudRuleThresholds,
  FSPFraudTrans,
  FSPPayerFraudOverride,
  FSPPayerType,
  FSPPaynet,
  FSPSchedTrans,
  Group,
  InternalAccount,
  InternalAccountAudit,
  Limit,
  LimitRule,
  LimitRuleImport,
  Notification,
  OneTimePasscode,
  OneTimePasscodeAudit,
  OneTimePasscodeEvent,
  OneTimePasscodeEventConfig,
  OneTimePasscodeInfo,
  OnHoldRule,
  OntracUser,
  Org,
  PageSetting,
  PayALoan,
  PayALoanAudit,
  Payee,
  PayeeAudit,
  Payer,
  PayerAudit,
  PayerSearch,
  PayerType,
  PayerUser,
  PayerUserAudit,
  PaymentResearch,
  PaymentResearchSearch,
  Paynet,
  Permission,
  ProcessingWindow,
  Reason,
  RecentFSP,
  RecentPayer,
  RecentTicket,
  RecentTicketPaymentOps,
  Recipient,
  RecipientAudit,
  RecipientDirect,
  Report,
  ReportType,
  ResearchNote,
  ResearchReason,
  Role,
  Route,
  Rtn,
  RtnInfo,
  SchedTranFraud,
  ScheduledTransaction,
  ScheduledTransactionAudit,
  ScrollRef,
  SSOErrorLog,
  SSOUri,
  Switch,
  SwitchAddress,
  SwitchAlias,
  SwitchMask,
  SwitchMatch,
  TransactionHistory,
  TransactionHistoryAudit,
  User,
  UserFspConfig,
  UserProfile,
  UserRole,
  ViewAs
);

export default orm;
