import { LOAD_FSP_PROFILE } from 'model/export';
import { UPDATE } from 'model/fspConfig';
import { selector } from 'selectors/busy';
import { TRANSACTION_TYPE_BILL_PAY, GENERAL_CODE, SSO_CODE } from 'consts';

export const A2A_CONFIG = 'a2aConfig';
export const A2A_IN_TOOLTIP_PROPS = {
  title: 'Controls transfers FROM an EXTERNAL account INTO an INTERNAL account',
};

export const A2A_OUT_TOOLTIPS_PROPS = {
  title:
    'Controls transfer FROM an INTERNAL account OUT TO an EXTERNAL account',
};
export const A2A_INTERNAL_TRANSFER_TOOLTIP_PROPS = {
  title:
    'Controls ability to transfer FROM an INTERNAL account INTO another INTERNAL account',
};
export const ALERTS_LABEL = 'Alerts';
export const DENY_LIST_LABEL = 'Deny List';
export const CORE_DIRECT_CODE = 'coredirect';
export const CORE_DIRECT_GENERAL_CODE = `${CORE_DIRECT_CODE}${GENERAL_CODE}`;
export const CORE_DIRECT_PCE_CODE = 'pce';
export const CORE_DIRECT_REALTIME_HOLDING_ID = 'realtime-holding-id';
export const REALTIME_CODE = 'realtime';
export const A2A_CODE = 'a2a';
export const ACH_FILE_CODE = 'achfile';
export const A2A_GENERAL_CODE = `${A2A_CODE}${GENERAL_CODE}`;
export const A2A_ACH_FILE_CODE = `${A2A_CODE}${ACH_FILE_CODE}`;
export const A2A_ACCOUNT_VERIFICATION_CODE = `${A2A_CODE}accountverification`;
export const BP_GENERAL_CODE = `${TRANSACTION_TYPE_BILL_PAY}${GENERAL_CODE}`;
export const BP_CHECK_CODE = `${TRANSACTION_TYPE_BILL_PAY}check`;
export const BRANDING_CODE = 'branding';
export const BRANDING_LABEL = 'Branding, Display, & Communication';
export const BRANDING_LOGO_CODE = 'logo';
export const BRANDING_LOGO_LABEL = 'FSP Logos';
export const BRANDING_MASKING_CODE = `${BRANDING_CODE}masking`;
export const BRANDING_MASKING_LABEL = 'Masking';
export const BRANDING_MESSAGING_CODE = `${BRANDING_CODE}messaging`;
export const BRANDING_MESSAGING_LABEL = 'Messaging';
export const BRANDING_RECIPIENT_UI_CODE = `${BRANDING_CODE}recipientui`;
export const BRANDING_RECIPIENT_UI_LABEL = 'Recipient UI';
export const FRAUD_MONITOR_CODE = 'fraudMonitor';
export const FRAUD_MONITOR_LABEL = 'Fraud Monitor';
export const FRAUD_MONITOR_INTERNAL_LABEL = 'Internal Only Configs';
export const FRAUD_MONITOR_INTERNAL_GENERAL_CONFIGS_LABEL = 'General Configs';
export const FRAUD_MONITOR_INTERNAL_GENERAL_CONFIGS_CODE = `${FRAUD_MONITOR_CODE}internalGeneralConfigs`;
export const FRAUD_MONITOR_INTERNAL_PRODUCT_OVERRIDES_LABEL =
  'Product-Level Overrides';
export const FRAUD_MONITOR_INTERNAL_PRODUCT_OVERRIDES_CODE = `${FRAUD_MONITOR_CODE}internalProductOverrides`;
export const FRAUD_MONITOR_INTERNAL_CODE = `${FRAUD_MONITOR_CODE}internal`;
export const FRAUD_MONITOR_CLIENT_FACING_PRODUCT_LEVEL_OVERRIDE =
  'Product-Level Override';
export const FRAUD_MONITOR_CLIENT_FACING_LABEL = 'Client-Facing';
export const FRAUD_MONITOR_CLIENT_FACING_CODE = `${FRAUD_MONITOR_CODE}clientfacing`;
export const FRAUD_MONITOR_CLIENT_FACING_NOTIFICATION_PREFERENCES_LABEL =
  'Notifications Preferences';
export const FRAUD_MONITOR_CLIENT_FACING_NOTIFICATION_PREFERENCES_CODE = `${FRAUD_MONITOR_CODE}notificationpreferences`;
export const FRAUD_MONITOR_CLIENT_FACING_SCORE_THRESHOLDS_LABEL =
  'Score Thresholds';
export const FRAUD_MONITOR_CLIENT_FACING_SCORE_THRESHOLDS_CODE = `${FRAUD_MONITOR_CLIENT_FACING_CODE}score`;
export const FRAUD_MONITOR_CLIENT_FACING_AMOUNT_THRESHOLDS_LABEL =
  'Amount Thresholds';
export const FRAUD_MONITOR_CLIENT_FACING_AMOUNT_THRESHOLDS_CODE = `${FRAUD_MONITOR_CLIENT_FACING_CODE}amount`;
export const GENERAL_LABEL = 'General';
export const LOGO_SUB_TITLE =
  'The FSP Logo value stored here will be used primarily for proof of payment. If no Alternate Logo value is provided, this will also be used for the P2P recipient UI.';
export const NOTIFY_CONFIG = 'notifyConfig';
export const ONTRAC_DISPLAY_LABEL = 'Ontrac Display';
export const PAY_A_LOAN_CODE = 'payALoan';
export const PAY_A_LOAN_FEES_LABEL = 'Fees';
export const PAY_A_LOAN_FEES_CODE = 'processingFees';
export const PAY_A_LOAN_LABEL = 'Pay A Loan';
export const PAY_A_LOAN_GENERAL_CODE = `${PAY_A_LOAN_CODE}${GENERAL_CODE}`;
export const PAY_A_LOAN_GENERAL_CONFIGS_LABEL = 'General Configs';
export const P2P_REALTIME_FEE_CONTAINER_ID = 'p2p-realtime-fee-container-id';
export const SSO_CONFIG = 'ssoConfig';
export const SSO_GENERAL_CODE = `${SSO_CODE}general`;
export const SSO_OVERWRITE_CODE = `${SSO_CODE}overwrite`;
export const SSO_OVERWRITE_LABEL = 'Overwrite';
export const USPS_ADDRESS = 'uspsAddress';

export const loadingFspProfileSelector = (state) =>
  selector(state, LOAD_FSP_PROFILE);
export const updatingFspConfigSelector = (state) => selector(state, UPDATE);
