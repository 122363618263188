import PropTypes from 'prop-types';
import { useEffect, useCallback } from 'react';
import { ON } from 'consts';
import {
  Features,
  Mask,
  MaskPopover,
  TextFieldAction,
} from 'components/common';
import { Grid } from '@material-ui/core';
import {
  ReadOnlyFormControl,
  ReadOnlyInputWithMenu,
} from 'components/common/fields';
import { StatusGridNoContainer } from 'components/common/StatusGrid';
import { loadAccountAutopay } from 'model/autopay';
import {
  UPDATE_FSP_DDA_ID_STR,
  updateFspDdaIdStr,
} from 'model/internalAccount';
import { makeStyles } from '@material-ui/core/styles';
import { selectorAccountAutopay } from 'selectors/autopay';
import {
  unmaskAchNumber,
  unmaskFspDdaId,
  unmaskMicrNumber,
} from 'sdk/internalAccount';
import { getAuth } from 'util/index';
import { hasPermission } from 'util/rbac';
import {
  CSR_ACCESS,
  FULL_ACCESS_PAYMENT_OPS,
} from 'components/common/permissions';
import { useDispatch, useSelector } from 'react-redux';
import DetailsRenderBusinessAccount from './DetailsRenderBusinessAccount';
import DetailsRenderCreditPay from './DetailsRenderCreditPay';
import StatusSelector from './StatusSelector';

const anchorOrigin = { horizontal: 'right', vertical: 'center' };
const transformOrigin = { horizontal: 'right', vertical: 'center' };
const PopoverProps = { anchorOrigin, transformOrigin };

const features = (item) => [
  {
    checked: item.ddaBillPayFromOffOn === ON,
    key: `${item.id}k1`,
    label: 'Pay From',
  },
  {
    checked: item.ddaTransferFromOffOn === ON,
    key: `${item.id}k2`,
    label: 'Transfer From',
  },
  {
    checked: item.ddaTransferToOffOn === ON,
    key: `${item.id}k3`,
    label: 'Transfer To',
  },
];

const useStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

const DetailsRender = ({ item, options }) => {
  const classes = useStyles();
  const auth = useSelector(getAuth);
  const { autopayRuleAt, fspId, id } = item;
  const dispatch = useDispatch();
  const autopay = useSelector((state) =>
    selectorAccountAutopay(state, item?.id)
  );

  const { statusDerived } = autopay;

  useEffect(() => {
    if (autopayRuleAt && !statusDerived) {
      dispatch(loadAccountAutopay({ ddaId: id, fspId }));
    }
  }, [autopayRuleAt, dispatch, fspId, id, statusDerived]);

  const handleUpdateFSPDDAIdStr = useCallback(
    (value) => {
      dispatch(
        updateFspDdaIdStr({
          payload: {
            payerId: item.payerId,
            ddaId: item.id,
            fspDdaIdStr: value,
          },
          options,
        })
      );
    },
    [dispatch, options, item]
  );

  return (
    <Grid className={classes.grid} container>
      <Grid container>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            inputNode={
              <ReadOnlyInputWithMenu
                label={
                  item.ddaTypeDerived
                    ? item.ddaTypeDerived
                    : item.externalDdaType
                }
                menuNode={
                  <Features
                    disabled
                    features={features(item)}
                    title="Account Features"
                  />
                }
              />
            }
            label="Type"
            value={item.ddaTypeDerived}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label="Description"
            value={item.descriptionDerived}
          />
        </Grid>
        <Grid item xs={2}>
          <ReadOnlyFormControl label="Nickname" value={item.nickName} />
        </Grid>
        <Grid item xs={4}>
          <TextFieldAction
            alertMessage="Are you sure you want to update FSP DDA ID?"
            actionType={UPDATE_FSP_DDA_ID_STR}
            enabled={hasPermission(auth, FULL_ACCESS_PAYMENT_OPS, CSR_ACCESS)}
            label="FSP DDA ID"
            isValueRequired
            onChange={handleUpdateFSPDDAIdStr}
            readOnlyNode={
              <ReadOnlyFormControl
                inputNode={
                  <MaskPopover
                    api={() =>
                      unmaskFspDdaId({
                        fspId: options.fspId,
                        id: item.id,
                      })
                    }
                    PopoverProps={PopoverProps}
                    value={item.fspDdaIdMask}
                  />
                }
                label="FSP DDA ID"
                value={item.fspDdaIdMask}
              />
            }
            value={item.fspDdaId}
          />
        </Grid>
      </Grid>
      <Grid container>
        <StatusGridNoContainer
          sizes={[3, 3, 4]}
          status={item.ddaStatus}
          statusNode={<StatusSelector item={item} options={options} />}
          statusText={item.ddaStatusText}
          statusUpdatedAt={item.ddaStatusAtDerived}
        />
        <Grid item xs={2}>
          <ReadOnlyFormControl label="Ontrac DDA ID" value={item.id} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            inputNode={
              <Mask
                api={() =>
                  unmaskMicrNumber({ fspId: options.fspId, id: item.id })
                }
                value={item.accountMicrNbrMask}
              />
            }
            label="MICR Account Number"
            value={item.accountMicrNbrMask}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            inputNode={
              <Mask
                api={() =>
                  unmaskAchNumber({ fspId: options.fspId, id: item.id })
                }
                value={item.accountAchNbrMask}
              />
            }
            label="ACH Account Number"
            value={item.accountAchNbrMask}
          />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl
            label="Available Balance"
            value={item.availBalanceDerived}
          />
        </Grid>
        <DetailsRenderBusinessAccount
          title={item.accountTitle}
          uspsAddress={item.uspsAddress}
        />
        <DetailsRenderCreditPay
          autopay={autopay}
          item={item}
          statusDerived={statusDerived}
        />
      </Grid>
    </Grid>
  );
};

DetailsRender.propTypes = {
  item: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
};

export default DetailsRender;
