import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createRequestConst,
  createUpdateConst,
} from '../util';
import { addDerived } from './util/fspConfig';

export default class FSPConfig extends Model {
  static get modelName() {
    return 'FSPConfig';
  }

  static reducer({ type, data }, FSPConfig) {
    switch (type) {
      case LOAD.SUCCESS:
        data.response.forEach((r) => {
          FSPConfig.upsert(addDerived(r));
        });
        break;

      case LOAD_ID.SUCCESS:
      case UPDATE.SUCCESS:
        FSPConfig.upsert(addDerived(data.response));
        break;

      case CREATE.SUCCESS:
        FSPConfig.create(addDerived(data.response));
        break;

      case REMOVE.ACTION:
        FSPConfig.filter(({ fspId }) => fspId === data).delete();
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      a2aConfig: attr(),
      a2aInOffOn: attr(),
      a2aOutOffOn: attr(),
      billSavingConfig: attr(),
      bpConfig: attr(),
      bpOffOn: attr(),
      brandConfig: attr(),
      cdHoldAccount: attr(),
      checkConfig: attr(),
      coreStatementId: attr(),
      createdAt: attr(),
      createdAtLabel: attr(), // derived
      creditPayOffOn: attr(),
      daysToFund: attr(),
      defaultA2AInternalFromOffOn: attr(),
      defaultA2AInternalToOffOn: attr(),
      displayAccountNbrMaskLong: attr(),
      displayAccountNbrMaskShort: attr(),
      fsp: attr(),
      fspAlternateLogo: attr(),
      fspDdaMaskLength: attr(),
      fspId: attr(),
      fspLogo: attr(),
      fspName: attr(),
      fspPayerMaskLength: attr(),
      fspUserMaskLength: attr(),
      href: attr(),
      id: attr(),
      insUserId: attr(),
      insightOffOn: attr(),
      ipayBillerDirectoryOffOn: attr(),
      notifyConfig: attr(),
      offset: attr(),
      otpConfig: attr(),
      otpOffOn: attr(),
      palOffOn: attr(),
      p2pConfig: attr(),
      p2pOffOn: attr(),
      pilotEnabledOffOn: attr(),
      products: attr(),
      realtimeFundingService: attr(),
      ssoA2AOffOn: attr(),
      ssoBPOffOn: attr(),
      ssoConfig: attr(),
      ssoP2POffOn: attr(),
      ssoPalOffOn: attr(),
      transactOnBehalfConfig: attr(),
      transactOnBehalfOffOn: attr(),
      updUserId: attr(),
      updatedAt: attr(),
      uxVendorSupportScript: attr(),
      windowId: attr(),
    };
  }
}

const name = FSPConfig.modelName;

export const CREATE = createAddConst(name);
export const LOAD = createRequestConst(name);
export const LOAD_ID = createRequestConst(`${name}_id`);
export const PREVIEW_RECIPIENT = createRequestConst(
  `${name}_preview_recipient`
);
export const PREVIEW_SSO = createRequestConst(`${name}_preview_sso`);
export const REMOVE = createDeleteConst(name);
export const UPDATE = createUpdateConst(name);
export const UPDATE_PREVIEW_RECIPIENT = createUpdateConst(
  `${name}_update_preview_recipient`
);
export const UPDATE_PREVIEW_SSO = createUpdateConst(
  `${name}_update_preview_sso`
);

export const create = createAction(CREATE);
export const load = createAction(LOAD);
export const loadById = createAction(LOAD_ID);
export const previewRecipient = createAction(PREVIEW_RECIPIENT);
export const previewSso = createAction(PREVIEW_SSO);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
