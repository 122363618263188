import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'ramda';
import { Card, CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  ReadOnlyFormControl,
  ReadOnlyInputWithMenu,
} from 'components/common/fields';
import { Skeleton } from 'components/common';
import { load } from 'model/eBillAccount';
import { load as loadEBillUser } from 'model/eBillUser';
import selector from 'selectors/eBillAccount';
import selectorEBillUser from 'selectors/eBillUser';
import DetailAccount from './DetailAccount';
import DetailStatement from './DetailStatement';

const useStyles = makeStyles(() => ({
  card: {
    width: 350,
  },
}));

const getTitle = (eBillAccount) => {
  if (!eBillAccount) {
    return <Skeleton />;
  }
  if (eBillAccount.statements && !isEmpty(eBillAccount.statements)) {
    return eBillAccount.statements[0].billOn;
  }
  return 'Most recent';
};

const getStatements = (eBillAccount) => {
  if (!eBillAccount) {
    return null;
  }
  return eBillAccount.statements;
};

const CREDIT_CARD_ACCOUNT_TYPE = 'creditcard';
const getIsCreditCardAccountType = (eBillAccount) => {
  const accountType = eBillAccount?.accountType ?? '';
  return accountType === CREDIT_CARD_ACCOUNT_TYPE;
};

const Detail = ({
  billerAccountNumberMask,
  fspId,
  name,
  payerEbillerAccountId,
  payerEbillernetEbillerId,
  payerId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const eBillAccount = useSelector((state) =>
    selector(state, payerEbillerAccountId)
  );
  const { netUserIdStr } = useSelector((state) =>
    selectorEBillUser(state, payerEbillernetEbillerId)
  );

  const loading = !eBillAccount;
  const isCreditCardAccountType = getIsCreditCardAccountType(eBillAccount);
  const statements = getStatements(eBillAccount);
  const title = getTitle(eBillAccount);

  useEffect(() => {
    if (!eBillAccount) {
      dispatch(load({ fspId, payerEbillerAccountId }));
    }
  }, [eBillAccount, dispatch, fspId, payerEbillerAccountId]);

  useEffect(() => {
    dispatch(loadEBillUser({ fspId, payerEbillernetEbillerId }));
  }, [dispatch, fspId, payerEbillernetEbillerId]);

  return (
    <ReadOnlyFormControl
      inputNode={
        !loading && (
          <ReadOnlyInputWithMenu
            label={title}
            menuNode={
              <Card elevation={0}>
                <CardContent className={classes.card}>
                  <Grid item xs={12}>
                    <ReadOnlyFormControl
                      label="eBill User ID"
                      value={netUserIdStr}
                    />
                  </Grid>
                  {!statements || isEmpty(statements) ? (
                    <DetailAccount
                      eBillAccount={eBillAccount}
                      isCreditCardAccountType={isCreditCardAccountType}
                      title={title}
                    />
                  ) : (
                    <DetailStatement
                      billerAccountNumberMask={billerAccountNumberMask}
                      fspId={fspId}
                      isCreditCardAccountType={isCreditCardAccountType}
                      name={name}
                      statements={statements}
                      payerId={payerId}
                    />
                  )}
                </CardContent>
              </Card>
            }
          />
        )
      }
      label="View eBill Details"
      value={title}
    />
  );
};

Detail.propTypes = {
  billerAccountNumberMask: PropTypes.string.isRequired,
  fspId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  payerEbillerAccountId: PropTypes.number.isRequired,
  payerEbillernetEbillerId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default Detail;
