import { CLAIM } from 'model/paymentResearch';
import { createDeleteConst, createAction, FAILURE } from 'util/actions';
import { endsWith, includes, map, head } from 'ramda';
import { HTTP_STATUS_CODE_426 } from 'consts';
import { KEEP_ALIVE, LOGIN, UPDATE } from 'reducers/authToken';
import { LOAD as LOAD_REPORT_TYPE } from 'model/reportType';
import { LOAD_FRACTIONAL_NUMBER } from 'model/rtn';
import { RECYCLED_PASSWORD_API_ERROR } from 'apiErrors';
import { VALIDATE } from 'reducers/validate';
import { VERIFY } from 'reducers/verify';
import { CANCEL, REVIEW } from 'model/fspSchedTrans';
import { isDuplicateUserError } from './adminAddUser';
import { RESET } from './reset';

const defaultErrorMessage = {};
const initialState = [];
const name = 'errorMessages';

const DELETE = createDeleteConst('ERROR_MESSAGES');
export const deleteMessage = createAction(DELETE);

const messageFor = (error, data) => {
  let retVal = error;
  if (typeof error !== 'string') {
    retVal = findFormattedMessage(error, data);
  }

  return retVal;
};

const findFormattedMessage = ({ message, number }, data) => {
  let retVal;

  switch (number) {
    case 2036:
      retVal = `Payee ID ${data.id} could not be found`;
      break;

    case 2069:
      retVal = 'This item already exists in this case-insensitive list.';
      break;

    case 4122:
      retVal =
        "User can't be inactivated because some transactions are currently in progress. Please try again at a later time.";
      break;

    case 4132:
      retVal =
        'Unable to complete merge until scheduled transactions are processed.';
      break;

    default:
      retVal = message;
      break;
  }

  return retVal;
};

export const selector = (state) => head(state[name]) ?? defaultErrorMessage;

export default (state = initialState, { data, error, type }) => {
  if (
    type !== KEEP_ALIVE.FAILURE &&
    type !== LOGIN.FAILURE &&
    type !== RESET.FAILURE &&
    type !== UPDATE.FAILURE &&
    type !== VERIFY.FAILURE &&
    type !== VALIDATE.FAILURE &&
    type !== CLAIM.FAILURE &&
    type !== LOAD_REPORT_TYPE.FAILURE &&
    type !== REVIEW.FAILURE &&
    type !== CANCEL.FAILURE &&
    type !== LOAD_FRACTIONAL_NUMBER.FAILURE &&
    error &&
    error.number !== 2043 &&
    error.number !== 2164 &&
    error.number !== 2074 &&
    error.number !== 2120 &&
    error.number !== 4136 &&
    error.number !== RECYCLED_PASSWORD_API_ERROR &&
    !isDuplicateUserError(type, error) &&
    endsWith(FAILURE, type)
  ) {
    const message = messageFor(error, data);

    if (
      includes(
        message,
        map((err) => messageFor(err, data), state)
      )
    ) {
      return state;
    }

    return [
      ...state,
      typeof error === 'string' ? error : { ...error, message },
    ];
  }

  if (error?.number === HTTP_STATUS_CODE_426) {
    return [...state, error];
  }

  if (type === DELETE.ACTION) {
    return initialState;
  }

  return state;
};
