import { client } from 'util/sdk';
import { GET } from 'util/api';
import { FSPS } from 'consts';

const returnModel = { name: 'palConfig' };

const loadURL = (fspId) => `/v2/${FSPS}/${fspId}/fspconfigs/pal`;

// eslint-disable-next-line import/prefer-default-export
export const load = (fspId) =>
  client.executeRequest(GET, loadURL(fspId), { returnModel });
