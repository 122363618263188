import Payer from 'model/payer';
import { CREATE } from 'model/paymentResearch';
import { attr, fk, Model } from 'redux-orm';
import {
  createAction,
  createLoadConst,
  createRequestConst,
  createUpdateConst,
} from '../util';
import { derived } from './util/transactionHistory';

export default class TransactionHistory extends Model {
  static get modelName() {
    return 'TransactionHistory';
  }

  static reducer({ type, data }, TransactionHistory, { Payer }) {
    switch (type) {
      case LOAD.SUCCESS:
        if (Payer.idExists(data.props ? data.props.payerId : 0)) {
          Payer.withId(data.props.payerId).transactionHistory.delete();
        }
        if (data.response) {
          data.response.forEach((r) => TransactionHistory.upsert(derived(r)));
        }
        break;

      case LOAD_ID.SUCCESS:
        TransactionHistory.upsert(derived(data.response));
        break;

      case LOAD_QUERY_PARAMS.SUCCESS:
        data.response.forEach((r) => TransactionHistory.upsert(derived(r)));
        break;

      case CREATE.SUCCESS:
        TransactionHistory.withId(data.response.histId).update({
          // eslint-disable-next-line no-plusplus
          trrCount: ++TransactionHistory.withId(data.response.histId).trrCount,
        });
        break;

      case LOAD_UPDATE_PAYMENT.ACTION:
      case UPDATE_PAYMENT_NOC.ACTION:
      case UPDATE_PAYMENT_NOR.ACTION:
        TransactionHistory.withId(data.id).update({
          ...data,
          updateSucceededDerived: false,
        });
        break;

      case UPDATE_PAYMENT_NOC.SUCCESS:
      case UPDATE_PAYMENT_NOR.SUCCESS:
        TransactionHistory.withId(data.props.id).update({
          ...data,
          updateSucceededDerived: true,
        });
        break;

      case UPDATE_RETURN_STATUS.SUCCESS:
        TransactionHistory.withId(data.props.id).update(data);
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      a2aTraceNumber: attr(),
      amount: attr(),
      amountDerived: attr(), // derived
      authDeniedProcessOn: attr(), // Added w/ API-1047
      authorizedProcessOn: attr(),
      autopayRuleId: attr(),
      billerAccountNumberMask: attr(),
      billerId: attr(),
      businessDaysSinceDeliverOnDerived: attr(), // derived
      checkMemo: attr(),
      checkNumber: attr(),
      confirmationNumber: attr(),
      coreAccountNumberMask: attr(),
      conversionDataOffOn: attr(),
      createdAt: attr(),
      dcExpMonth: attr(),
      dcExpYear: attr(),
      dcFeeAmount: attr(),
      dcHolderName: attr(),
      dcNumberMask: attr(),
      dcNumberToken: attr(),
      dcType: attr(),
      ddaAccountNbrMask: attr(),
      ddaAccountSuffix: attr(),
      ddaAchRtn: attr(),
      ddaFractRtn: attr(),
      ddaId: attr(),
      ddaMicrRtn: attr(),
      ddaWireRtn: attr(),
      deliver: attr(),
      deliverOn: attr(),
      deliverOnDerived: attr(), // derived
      deliveryMethodValue: attr(), // derived
      description: attr(),
      destination: attr(),
      destinationAccountSuffix: attr(), // derived
      destinationMask: attr(),
      disburseService: attr(),
      disburseStateDerived: attr(), // derived
      disburseType: attr(),
      disburseTypeDerived: attr(), // derived
      expiredProcessOn: attr(), // Added w/ API-1047
      extDdaAccountNbrMask: attr(),
      extDdaAchRtn: attr(),
      extDdaFiName: attr(),
      extDdaWireRtn: attr(),
      externalDdaId: attr(),
      feeAmount: attr(),
      feeAmountDerived: attr(),
      foreverOffOn: attr(),
      frequency: attr(),
      fsp: attr(),
      fspId: attr(),
      fundingAccount: attr(),
      fundingAccountSuffix: attr(), // derived
      fundingMask: attr(),
      fundingResponse: attr(), // derived
      fundingService: attr(),
      hostRespCode: attr(), // Added w/ ON-305
      href: attr(),
      id: attr(),
      insUserId: attr(),
      intelligentMailBarcode: attr(),
      invoices: attr(), // optional entry in response - business payers only
      ipayNetBillerId: attr(),
      ipayNetTrackingNumber: attr(),
      isFIDirectDerived: attr(), // derived
      modelId: attr(),
      networkResponseCode: attr(), // Added w/ API-1047
      networkResponseCodeStr: attr(), // Added w/ API-1047
      offset: attr(),
      p2pDirectOffOn: attr(),
      p2pTraceNumber: attr(),
      pal: attr(),
      palEscrow: attr(), // we may be able to delete these as they are under pal
      palInterest: attr(),
      palPrincipal: attr(),
      payeeId: attr(),
      payeeName: attr(),
      payeeUspsAddress: attr(),
      payeeUspsStandardAddress: attr(),
      payerCheckName: attr(),
      payerId: fk(Payer.modelName, 'transactionHistory'),
      payerName: attr(),
      payerPhone: attr(),
      payerType: attr(),
      payerUspsAddress: attr(),
      processOn: attr(),
      recipientEmail: attr(),
      recipientId: attr(),
      recipientMobilePhone: attr(),
      recipientName: attr(),
      recipientNote: attr(),
      recipientNoteEncodedOffOn: attr(),
      refundProcessOn: attr(), // Added w/ API-1047
      refundHostResponseCode: attr(),
      refundResponseCode: attr(),
      rejectProcessOn: attr(), // Added w/ API-1047
      respCodeIdStr: attr(),
      returnProcessOn: attr(), // Added w/ API-1047
      rppsTraceNumber: attr(),
      schedId: attr(),
      scheduleDeliverOn: attr(),
      scheduleSendOn: attr(),
      sendOn: attr(),
      sendOnDerived: attr(), // derived
      sendOrDeliver: attr(),
      shippingTrackingNumber: attr(),
      status: attr(),
      statusDerived: attr(), // derived
      subtitle: attr(), // derived - used in Audit - https://payrailz.atlassian.net/browse/ON-536
      systemCancelProcessOn: attr(), // Added w/ API-1047
      title: attr(), // derived - used in Audit - https://payrailz.atlassian.net/browse/ON-536
      totalAmountDerived: attr(), // derived
      trackingLabelDerived: attr(), // derived
      trackingNumberDerived: attr(), // derived
      transactionType: attr(),
      transactionTypeDerived: attr(), // derived
      trrCount: attr(),
      updUserId: attr(),
      updateSucceededDerived: attr(), // derived
      updatedAt: attr(),
      uspsTrackingNumber: attr(),
      venmoReceiver: attr(),
      venmoRecipientType: attr(),
      windowId: attr(),
    };
  }
}

const name = TransactionHistory.modelName;

export const LOAD = createLoadConst(name);
export const LOAD_ID = createRequestConst(`${name}_id`);
export const LOAD_QUERY_PARAMS = createRequestConst(`${name}_sched_id`);
export const LOAD_UPDATE_PAYMENT = createUpdateConst(
  `${name}_loadUpdatePayment`
);
export const UPDATE_PAYMENT_NOC = createUpdateConst(`${name}_updatePaymentNoc`);
export const UPDATE_PAYMENT_NOR = createUpdateConst(`${name}_updatePaymentNor`);
export const UPDATE_RETURN_STATUS = createRequestConst(
  `${name}_updateReturnStatus`
);

export const load = createAction(LOAD);
export const loadById = createAction(LOAD_ID);
export const loadByQueryParams = createAction(LOAD_QUERY_PARAMS);
export const loadUpdatePayment = createAction(LOAD_UPDATE_PAYMENT);
export const updatePaymentNoc = createAction(UPDATE_PAYMENT_NOC);
export const updatePaymentNor = createAction(UPDATE_PAYMENT_NOR);
export const updateReturnedStatus = createAction(UPDATE_RETURN_STATUS);
