import {
  loadDenyListConfig,
  createDenyListConfig,
  updateDenyListConfig,
} from 'sdk/fspDenyListConfig';
import {
  CREATE_DENY_LIST_CONFIG,
  LOAD_DENY_LIST_CONFIG,
  UPDATE_DENY_LIST_CONFIG,
} from 'model/fspDenyListConfig';
import { takeLatest } from 'redux-saga/effects';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeLatest(
    LOAD_DENY_LIST_CONFIG.ACTION,
    callAction({
      api: loadDenyListConfig,
      dataName: ORM,
      type: LOAD_DENY_LIST_CONFIG,
    })
  );
  yield takeLatest(
    CREATE_DENY_LIST_CONFIG.ACTION,
    callAction({
      api: createDenyListConfig,
      dataName: ORM,
      type: CREATE_DENY_LIST_CONFIG,
    })
  );
  yield takeLatest(
    UPDATE_DENY_LIST_CONFIG.ACTION,
    callAction({
      api: updateDenyListConfig,
      dataName: ORM,
      type: UPDATE_DENY_LIST_CONFIG,
    })
  );
}
