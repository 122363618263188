import { useEffect, useState } from 'react';
import { Button, CardActions } from '@material-ui/core';
import { LOAD_CUSTOM as LOAD_RULES } from 'model/limitRule';
import { LOAD } from 'model/limit';
import { LOAD_ID } from 'model/payer';
import { isLoadingState as isLoading } from 'reducers/loading';
import { isNil } from 'ramda';
import { selector } from 'selectors/limitRule';
import { useSelector } from 'react-redux';
import { withUpdateLimits } from 'components/common/permissions';
import usePayALoanPayerOffOn from 'hooks/usePayALoanPayerOffOn';
import LimitsCardHeaderActionsRemove from './LimitsCardHeaderActionsRemove';
import LimitsDialog from '../LimitsDialog';
import { useLimits } from '../LimitsContext';

const EDIT = 'Edit';
const SET = 'Set';
const loadingPayer = (state) => isLoading(state, LOAD_ID.ACTION);
const loadingLimit = (state) => isLoading(state, LOAD.ACTION);
const loadingLimitRules = (state) => isLoading(state, LOAD_RULES.ACTION);

const LimitsCardHeaderActions = () => {
  const [buttonText, setButtonText] = useState(SET);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [lastId, setLastId] = useState(null);
  const { expired, limitRule } = useLimits();
  const limitRules = useSelector(selector);
  const isLoadingPayer = useSelector(loadingPayer);
  const isLoadingLimit = useSelector(loadingLimit);
  const isLoadingLimitRules = useSelector(loadingLimitRules);
  const palEnabled = usePayALoanPayerOffOn();

  const handleClick = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    if (limitRule.id && lastId !== limitRule.id) {
      if (lastId) {
        // an update occurred, close the dialog
        setDialogOpen(false);
      }

      setLastId(limitRule.id);
    }
  }, [lastId, limitRule.id]);

  useEffect(() => {
    const text =
      !expired && !isNil(limitRule.expireDateDisplayDerived) ? EDIT : SET;

    if (buttonText !== text) {
      setButtonText(text);
    }
  }, [buttonText, expired, limitRule]);

  return limitRules?.length > 0 ? (
    <>
      <CardActions>
        <LimitsCardHeaderActionsRemove
          disabled={
            isLoadingLimit ||
            isLoadingLimitRules ||
            isLoadingPayer ||
            palEnabled
          }
        />
        <Button
          disabled={
            isLoadingLimit ||
            isLoadingLimitRules ||
            isLoadingPayer ||
            palEnabled
          }
          onClick={handleClick}
          variant="outlined"
        >
          {buttonText} Custom Limits
        </Button>
      </CardActions>
      {dialogOpen && (
        <LimitsDialog maxWidth="lg" onClose={handleDialogClose} open />
      )}
    </>
  ) : null;
};

export default withUpdateLimits(LimitsCardHeaderActions);
