import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createLoadConst,
  createUpdateConst,
} from '../util';

export default class AutomatchTemplate extends Model {
  static get modelName() {
    return 'AutomatchTemplate';
  }

  static reducer({ type, data }, AutomatchTemplate) {
    switch (type) {
      case CREATE.SUCCESS:
        AutomatchTemplate.create(data.response);
        break;

      case LOAD.SUCCESS: {
        if (data && Array.isArray(data.response)) {
          data.response.forEach((r) => AutomatchTemplate.upsert(r));
        }
        break;
      }

      case REMOVE.SUCCESS:
        AutomatchTemplate.withId(data.response.id).delete();
        break;

      case UPDATE.SUCCESS:
        AutomatchTemplate.upsert({
          ...data.response,
          dirtyDescription: false,
          dirtyScopes: false,
        });
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      billerAcctNbrStr: attr(),
      billerIdStr: attr(),
      billerNetworkIdStr: attr(),
      billerStatusStr: attr(),
      conversion: attr(),
      createdAt: attr(),
      deliverCheckExpedite: attr(),
      deliverCheckStd: attr(),
      deliverElecNow: attr(),
      deliverElecStd: attr(),
      deliverElecToday: attr(),
      deliverElecVc: attr(),
      description: attr(),
      dirtyDescription: attr(), // derived
      dirtyScopes: attr(), // derived
      execStateStr: attr(),
      fspIdStr: attr(),
      href: attr(),
      id: attr(),
      insUserId: attr(),
      lastJobType: attr(),
      name: attr(),
      payeeIdStr: attr(),
      payeeNameStr: attr(),
      payerIdStr: attr(),
      rppsBillerIdStr: attr(),
      updUserId: attr(),
      updateAtStr: attr(),
      updatedAt: attr(),
      uspsZipStr: attr(),
    };
  }
}

export const CREATE = createAddConst(AutomatchTemplate.modelName);
export const LOAD = createLoadConst(AutomatchTemplate.modelName);
export const REMOVE = createDeleteConst(AutomatchTemplate.modelName);
export const UPDATE = createUpdateConst(AutomatchTemplate.modelName);

export const create = createAction(CREATE);
export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
