import { useFSPId, usePayerId } from 'hooks';
import { useUserFspConfigSelector } from './selectors';
import { isOn } from '../util';
import usePayALoanOffOn from './usePayALoanOffOn';

const usePayALoanPayerOffOn = (fspId, payerId) => {
  let fspIdDerived = 0;
  if (fspId) {
    fspIdDerived = fspId;
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    fspIdDerived = useFSPId();
  }

  const isPalOffOn = usePayALoanOffOn(fspIdDerived);

  let payerIdDerived = 0;
  if (payerId) {
    payerIdDerived = payerId;
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    payerIdDerived = usePayerId();
  }

  const fspConfigUser = useUserFspConfigSelector(fspIdDerived, payerIdDerived);
  const isFspConfigUserPalOffOn = isOn(fspConfigUser?.palOffOn);

  return isPalOffOn && isFspConfigUserPalOffOn;
};

export default usePayALoanPayerOffOn;
