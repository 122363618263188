import { attr, Model } from 'redux-orm';
import {
  createAddConst,
  createAction,
  createLoadConst,
  createDeleteConst,
  createRequestConst,
  createUpdateConst,
} from '../util';

export default class FspDenyListConfig extends Model {
  static get modelName() {
    return 'FspDenyListConfig';
  }

  static reducer({ type, data }, FspDenyListConfig) {
    const id = data?.id;

    switch (type) {
      case LOAD_DENY_LIST_CONFIG.SUCCESS:
      case UPDATE_DENY_LIST_CONFIG.SUCCESS:
        FspDenyListConfig.delete();
        data?.response?.forEach((r) => FspDenyListConfig.upsert(r));
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      id: attr(),
      offeringId: attr(),
      fspDenyOffOn: attr(),
      globalDenyOffOn: attr(),
      description: attr(),
      updatedAt: attr(),
    };
  }
}

const name = FspDenyListConfig.modelName;

export const LOAD_DENY_LIST_CONFIG = createLoadConst(`${name}_dl_config`);
export const CREATE_DENY_LIST_CONFIG = createAddConst(`${name}_dl_config`);
export const UPDATE_DENY_LIST_CONFIG = createUpdateConst(`${name}_dl_config`);

export const loadDenyListConfig = createAction(LOAD_DENY_LIST_CONFIG);
export const createDenyListConfig = createAction(CREATE_DENY_LIST_CONFIG);
export const updateDenyListConfig = createAction(UPDATE_DENY_LIST_CONFIG);
